import React, { Component } from 'react';
import './Login.scss';
import TextField from '@material-ui/core/TextField';
import PasswordElement from '../../elements/form/password/PasswordElement';
import Button from '@material-ui/core/Button';
import TitleElement from '../../elements/title/TitleElement';
import { Link } from "gatsby"
import { navigate } from 'gatsby'
import { UserConsumer } from '../../contexts/UserContext';
import GoogleLogin from '../../elements/login/google/GoogleLogin';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    };
  }

  singIn = () => {
    this.user.custom.signInWithEmailAndPassword(this.state.email, this.state.password);
  }

  onChange = (e) => {
    var obj = [];
    obj[e.target.name] = e.target.value
    this.setState({...this.state, ...obj });
  }

  render() {
    return <div className="block login" >
      <UserConsumer>
        {
          user => {
            this.user = user;
            if (user.isLogin) {
              navigate('/');
            }
            else {
              return <>
                <form>
                  <TitleElement type="h1" >Inicia sesión</TitleElement>
                  <TextField label="Correo Electrónico" name="email" value={this.state.email} onChange={this.onChange} /><br/>
                  <PasswordElement name="password" value={this.state.password} onChange={this.onChange} />
                  <Button className="send" variant="contained" color="primary" onClick={this.singIn} onKeyDown={this.singIn} role="Boton para iniciar sesion" >
                    Enviar
                  </Button>
                  <Link className="p p-lostpassword" to="/olvido-su-contrasena">¿Olvidaste tu contraseña?</Link>
                  <Link className="p p-toregister" to="/registro">¿Quieres registrarte?</Link>
                </form>
                <div className="p-login-container" ><GoogleLogin></GoogleLogin></div>
              </>;
            }
          }
        }
      </UserConsumer>
    </div>
  }
}

export default Login;