import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class PasswordElement extends Component {
  constructor() {
    super();

    this.state = {
      showPassword: false,
      password: ''
    }
  }  
  
  handleClickShowPassword = (event) => {
    this.setState({
      showPassword: true
    });
  }

  handleMouseDownPassword = (event) => {
    this.setState({
      showPassword: false
    });
  }

  
  render() {
    var name='';
    var value='';
    var onChange='';
    
    var props = {};
    if (this.props) {
      if (this.props.name) {
        name = this.props.name;
      }
      if (this.props.value) {
        value = this.props.value;
      }
      if (this.props.onChange) {
        onChange = this.props.onChange;
      }
    }
    
    return <FormControl>
      <InputLabel>Password</InputLabel>
      <Input
        id="standard-adornment-password"
        type={this.state.showPassword ? 'text' : 'password'}
        className="p-input-password"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={this.handleClickShowPassword}
              onMouseDown={this.handleMouseDownPassword}
            >
              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        name={name}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  }
}

export default PasswordElement;