import React from 'react'
import { UserConsumer } from '../../../contexts/UserContext';
import './GoogleLogin.scss';

const GoogleLogin = (props) => {
  let thisUser = {};

  function loginGoogle() {
    thisUser.custom.googleLogin();
  };

  return <UserConsumer>{
      user => {
        thisUser = user;
        if (user.isLogin) {
          return <>Estas logueado</>;
        }
        else {
          return <button onClick={loginGoogle} className="p-login-button p-google-login" >Con Google/Gmail</button>;
        }
      }
    }
  </UserConsumer>

}

export default GoogleLogin